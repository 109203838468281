.App {
  overflow-x: hidden;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100 - 20px);
  /* width: 100%; */
  padding: 10px;
}
.app-header-text {
  font-size: 16px;
  color: black;
}
.App-link {
  color: #61dafb;
}
.hide{
  visibility: hidden;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ant-slider-handle {
  display: none;
}
.ant-slider-track{
  background-color: #6059E3 !important;
}
.grey-line {
  height: 1px;
  width: 100%;
  background-color: #D6D5E3
  ;
}
.w-full {
  width: 100%;
}
.w-full-center {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.mt-20 {
  margin-top: 20px;
}
.mb-20 {
  margin-bottom: 20px;  
}