.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  background: none repeat scroll 0 0 black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 0.5;
}
