.footer-container-main {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 10px 16px;
  height: 77px;
  border-top: 1px solid #efeef8;
  background: #fff;
  box-shadow: 0px -4px 25px 0px rgba(0, 0, 0, 0.05);

  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;

  .navigation {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    text-decoration: none;

    .icon {
      width: 19px;
      height: 19px;

      &.large {
        width: 56px;
        height: 56px;
      }
    }

    .text {
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: 15px;
      color: #d6d5e3;
    }

    &.active {
      .text {
        color: #6059e3 !important;
      }
    }
  }
  .nav-bar-icon{
    width: 24px;
    height: 24px;
  }
}
